<template>
  <div class="page-login">
    <a-row style="margin-bottom: 22px">
      <span class="tip-title">
        <span style="position: absolute;width: 58px;z-index: 100;"> 登录</span>
        <img class="zhenzhu" src="../../assets/images/zhenzhu.png" alt="">
      </span>
      <span type="link" @click="handleTabClickChanged" class="tip-sub-title cursor-pointer" style="float: right">{{
          customActiveKey == 'tab1' ? '短信验证登录' : '账号登录'
        }}</span>
    </a-row>
    <a-form-model ref="form" :model="values" :rules="rules">
      <a-form-model-item prop="user" v-if="type=='pwd'" class="a-form-item_pre">
        <a-input :size="size" v-model="values.user" placeholder="输入11位手机号" @change="changePhone">
          <i slot="prefix" class="meiye-icon meiye-shouji font4"></i>

        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="staff_password" v-if="type=='pwd'" class="yzmnamed">
        <a-input :size="size" v-model="values.staff_password" type="password" placeholder="密码">
          <i slot="prefix" class="meiye-icon meiye-mima font4"></i>
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="staff_mobile" v-if="type=='msg'">
        <a-input :size="size" v-model="values.staff_mobile" placeholder="输入11位手机号" @change="changePhone">
          <i slot="prefix" class="meiye-icon meiye-shouji font4"></i>
        </a-input>

      </a-form-model-item>
      <a-form-model-item prop="company_code" v-if=" companyList && companyList.length!=1">
        <a-select :size="size" allow-clear class="a-select_company" v-model="values.company_code" placeholder="请选择公司">
          <a-select-option v-for="it in companyList" :key="it.company_code" :value="it.company_code">
            {{it.company_name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item prop="verify" v-if="type=='msg'" class="yzmnamed">
        <a-input ref="CodeInput" :size="size" :allow-clear="false" v-model="values.verify" placeholder="输入验证码">
          <!-- <a-icon
            slot="prefix"
            type="lock"
            class="C999"
          /> -->
          <i slot="prefix" class="meiye-icon meiye-yanzhengma font4"></i>
        </a-input>
        <div class="flex justify-end getCaptcha cursor-pointer" :class="state.smsSendBtn?'disabled':''" type="link"
          tabindex="-1" :disabled="state.smsSendBtn" @click.stop.prevent="getCaptcha"
          v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"></div>
      </a-form-model-item>
      <a-form-model-item>
        <a-button :size="size" @click="onLogin" type="primary" class="W100 primaryBtn" style="">
          登录<a-icon style="position: absolute;top: 12px;" v-if="loading" type="loading" />
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <div class="FB FBJC-SB pointer">
          <!-- <span
            @click="type='pwd'"
            v-if="type=='msg'"
          >
            账号登录
          </span>
          <span
            @click="type='msg'"
            v-if="type=='pwd'"
          >
            短信登录
          </span> -->
          <!-- <router-link
            v-if="type=='pwd'"
            to="/user/reset-password"
          >
            忘记密码
          </router-link> -->
          <!-- <router-link
            v-if="customActiveKey=='tab1'"
            to="/user/reset-password"
            class="primarycolor"
          >
            忘记密码
          </router-link> -->
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
  import {
    mapActions
  } from 'vuex'
  import UserHeader from './modules/user-header'
  import {
    getSmsCode
  } from '@/api/user'
  export default {
    components: {
      UserHeader
    },
    data() {
      return {
        // 提交控制
        loading: false,
        // 表单尺寸，账户模块的表单都统一使用大号的
        size: 'large',
        // 表单值
        values: {
          staff_password: undefined,
          // user: 17773174606,
          user: undefined,
          auto: true,
          // verify: '000000',
          verify: undefined,
          company_code: undefined,
          staff_mobile: undefined,
          system_id: 2,
        },
        state: {
          smsSendBtn: false,
          time: 60
        },

        // 接口返回的错误提示
        message: '',
        // 校验规则
        rules: {
          staff_mobile: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入手机号'))
              }
              if (!this.$utils.testMobile(value)) {
                return callback(new Error('请输入正确的手机号'))
              }
              return callback()
            }
          }],
          staff_password: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入密码'))
              }
              // 密码强度校验
              // if (!this.$utils.testPassword(value)) {
              //   return callback(new Error('密码必须是 8 至 16 位大小写字母数字下划线组合'))
              // }
              return callback()
            }
          }],
          company_code: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请选择公司'))
              }
              return callback()
            }
          }],
          user: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请输入手机号'))
              }
              if (!this.$utils.testMobile(value)) {
                return callback(new Error('手机号格式错误'))
              }
              return callback()
            }
          }],
          verify: [{
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error('请填写验证码'))
              }
              return callback()
            }
          }]
        },
        type: 'msg', //'msg'
        companyList: null,
        customActiveKey: null,
      }
    },
    created() {
      //登录状态监听
      if (localStorage.getItem('Access-Token') && localStorage.getItem('token')) {
        this.$router.push('/overview')
      }
    },
    methods: {
      ...mapActions(['getCompany']),
      ...mapActions(['login']),
      ...mapActions(['pslogin']),
      onLogin() {
        let self = this
        console.log(this.getCompany);
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true
            // self.getCompany({ staff_mobile: this.values.staff_mobile }).then((res) => {
            // if (res.error_code == 0) {
            if (this.type == 'pwd') {
              delete this.values.auto
              delete this.values.verify
              delete this.values.staff_mobile
              if (!this.values.company_code) {
                this.loading = false
                return this.$message.error('请先选择公司！')
              }
              let datas = {
                staff_mobile: this.values.user,
                system_id: 2,
                company_code: this.values.company_code,
                staff_password: this.values.staff_password
              }
              this.pslogin(datas)
                .then(() => {
                  const {
                    query
                  } = this.$route
                  this.loading = false
                  this.$router.push('/overview')
                })
                .catch(e => {
                  this.message = e.message || '账号或密码错误'
                })
                .finally(() => {
                  this.loading = false
                })
            } else if (this.type == 'msg') {
              delete this.values.auto
              delete this.values.staff_password
              delete this.values.user
              this.login(this.values)
                .then((res) => {
                  if (res.status == 'fail') {
                    this.message = res.error.message
                  }
                  const {
                    query
                  } = this.$route
                  this.loading = false
                  this.$router.push('/overview')
                })
                .catch(e => {
                  this.message = e.message || '账号或密码错误'
                })
                .finally(() => {
                  this.loading = false
                })
            }

          } else {
            return false
          }
        })
      },
      onAlertClose() {
        this.message = ''
      },
      // 获取手机号

      // 获取公司列表
      getcompanyList() {
        let values = ''
        if (this.type == 'msg') {
          values = this.values.staff_mobile
          if (!this.values.staff_mobile) {
            this.$message.error('请先输入正确的手机号')
            return false
          }
          if (!this.$utils.testMobile(this.values.staff_mobile)) {
            this.$message.error('手机号格式错误')
            return false
          }
        } else {
          values = this.values.user
          if (!this.values.user) {
            this.$message.error('请先输入正确的手机号')
            return false
          }
          if (!this.$utils.testMobile(this.values.user)) {
            this.$message.error('手机号格式错误')
            return false
          }
        }

        this.getCompany({
          staff_mobile: values,
          system_id: 2
        }).then((res) => {
          if (res.status == 'success' || res.error_code == 0) {
            this.companyList = res.data
          }
        })
      },
      getCaptcha(e) {
        e.preventDefault()
        let self = this
        const {
          values,
          state,
        } = self
        if (!values.staff_mobile) {
          return this.$message.error('请输入手机号')
        }
        if (!this.$utils.testMobile(values.staff_mobile)) {
          return this.$message.error('手机号格式错误')
        }
        if (!values.company_code) {
          return this.$message.error('请选择公司')
        }

        state.smsSendBtn = true

        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            state.time = 60
            state.smsSendBtn = false
            window.clearInterval(interval)
          }
        }, 1000)

        const hide = self.$message.loading('验证码发送中..', 0)
        getSmsCode({
            staff_mobile: values.staff_mobile,
            company_code: values.company_code,
            system_id: 2
          })
          .then((res) => {
            setTimeout(() => {
              self.$refs.CodeInput && self.$refs.CodeInput.focus()
              hide()
            }, 300)

          })
          .catch((err) => {
            setTimeout(hide, 1)
            clearInterval(interval)
            state.time = 60
            state.smsSendBtn = false
            self.requestFailed(err)
          })
      },
      requestFailed(err) {
        this.state.loginBtn = false
        this.$notification['error']({
          message: '错误',
          description: err.error_msg || '请求出现错误，请稍后再试',
          duration: 4
        })
      },
      changePhone(e) {
        this.companyList = null
        this.values.company_code = undefined
        console.log(e.target.value, 'eeee');
        let value = e.target.value || ""
        if (this.$utils.testMobile(value)) {
          this.getCompany({
            staff_mobile: value,
            system_id: 2
          }).then((res) => {
            if (res.status == 'success' || res.error_code == 0) {
              this.companyList = res.data
              if (this.companyList.length == 1) {
                this.values.company_code = this.companyList[0].company_code
              }
            }
          }).catch(err => {
            console.log(err);
          })
        }
      },
      handleTabClickChanged() {
        const key = this.customActiveKey
        if (key == 'tab1') {
          this.customActiveKey = 'tab2'
          this.type = 'msg'
        } else {
          this.customActiveKey = 'tab1'
          this.type = 'pwd'
        }
        // this.isLoginError = false
        // this.form.resetFields()
        // this.$nextTick(() => {
        //   this.$refs.AccountInput && this.$refs.AccountInput.focus()
        //   this.$refs.PhoneInput && this.$refs.PhoneInput.focus()
        // })
      },
    },
  }
</script>
<style lang="less" scoped>
  .page-login {
    width: 100%;
    height: 600px;
    padding-top: 136px;

    .error-tips {
      margin: 33px 0 10px;
      height: 37px;
      opacity: 0;
      transition: opacity 0.25s ease-in;

      &.is-active {
        opacity: 1;
      }
    }
  }

  .primaryBtn.ant-btn-primary {
    width: 410px;
    height: 56px;
    background: @primaryColor;
    box-shadow: 0px 1px 5px 0px #a3a8ca, 0px 2px 4px 0px rgba(201, 155, 157, 0.09),
      inset 0px -5px 0px 0px @btnMainInsertDColor;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
    border: 1px solid #0bc7b9;
  }

  /deep/ .ant-form-item-children {
    .ant-input {
      color: #1f1f1f;
      background: @inputbgDColor;
      box-shadow: inset 0px 2px 0px 0px @inputinsertDColor;
      border-radius: 4px;
      border: 1px solid @inputborderDColor;
      height: 46px;

      &:hover {
        box-shadow: inset 0px 2px 0px 0px @inputinsertHColor;
        border: 1px solid @inputborderHColor;
        background: @inputbgHColor;
      }
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 27px;
  }

  /deep/ .ant-form-item-with-help {
    margin-bottom: 2px;
  }

  /deep/ .yzmnamed.ant-form-item-with-help {
    // margin-bottom: 18px;
    margin-bottom: 2px;
  }

  /deep/.ant-form-explain,
  .ant-form-extra {
    font-size: 14px;
  }

  /deep/ .a-select_company.ant-select .ant-select-selection {
    height: 46px;
    border-radius: 2px;
    border: 1px solid @inputborderDColor;
  }

  .getCaptcha {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 18px;
    line-height: 18px;
    width: 108px;
    border-left: 1px solid @baseColor46;
    display: flex;
    justify-content: center;
    padding: 0 16px 0 20px;
    font-size: 12px;
    // font-weight: 700;
    color: @baseColor11;
  }

  .disabled {
    pointer-events: none;
  }

  .pointer {
    cursor: pointer;
  }

  .tip-title {
    position: relative;
    width: 54px;
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #33323f;
    line-height: 30px;
    // &::after {
    //   display: block;
    //   position: absolute;
    //   right: -42px;
    //   top: -15px;
    //   z-index: -1;
    //   content: "";
    //   // width: 44px;
    //   // height: 44px;
    //   // background: linear-gradient(315deg, rgba(74,92,255,0) 0%, #747FFF 100%);
    //   // opacity: 0.43;
    //   // filter: blur(7px);
    //   width: 85px;
    //   height: 76px;
    //   background: url(/static/imgs/zhenzhu.png) no-repeat 0 0;
    //   background-size: 100% 100%;
    // }
  }

  label {
    font-size: 14px;
  }

  .tip-sub-title {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #33323f;
    line-height: 38px;

    &:hover {
      color: @baseColor11;
    }
  }

  .primarycolor {
    color: @baseColor11;
    height: 14px;
    line-height: 14px;
  }

  .font4 {
    color: @fontColor4;
  }

  .zhenzhu {
    position: absolute;
    left: 0px;
    top: -22px;
    width: 85px;
    height: 76px;
    z-index: 0;
  }
</style>